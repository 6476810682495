<template>
  <div class="edit-table">
    <div class="padding_50">
      <vxe-toolbar>
        <template v-slot:buttons>
          <vxe-button @click="productNameClick()" v-if="!disabled"
            >添加产品</vxe-button
          >
        </template>
      </vxe-toolbar>
      <vxe-table
        show-overflow=""
        ref="fineTable"
        :data="subjectList"
        height="300px"
      >
        <vxe-table-column min-width="100" field="rowIndex" title="序号">
          <template v-slot="{ rowIndex }">
            {{ rowIndex + 1 }}
          </template>
        </vxe-table-column>
        <vxe-table-column
          min-width="100"
          field="productLevelName"
          title="产品系列"
        >
        </vxe-table-column>
        <vxe-table-column min-width="100" field="productCode" title="产品编码">
        </vxe-table-column>
        <vxe-table-column min-width="100" field="productName" title="产品名称">
        </vxe-table-column>
        <vxe-table-column
          min-width="100"
          field="increaseLimitRatio"
          title="提额比例(%)"
          v-if="scope === '1'"
        >
          <template #default="{ row }">
            <el-input-number
              v-model="row.increaseLimitRatio"
              :precision="2"
              placeholder="提额比例"
              :disabled="disabled"
            ></el-input-number>
          </template>
        </vxe-table-column>
        <vxe-table-column
          title="操作"
          width="100"
          show-overflow
          v-if="notView !== 'view'"
          key="操作"
        >
          <template v-slot="{ row, rowIndex }">
            <!-- <span
              @click="deletRow(row, rowIndex)"
              style="color: red; cursor: pointer"
              >删除</span
            > -->
            <el-button type="text" size="mini" @click="deletRow(row, rowIndex)"
              >删除</el-button
            >
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'addForm',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    disabled: Boolean,
    notView: String,
    scope: {
      type: String,
      default: '0',
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log('🚀 ~ handler ~ val:', val);
        this.subjectList = val.subjectList || [];
      },
      deep: true,
    },
    disabled(val) {
      console.log('🚀 ~ disabled ~ val:', val);
    },
    notView(val) {
      console.log('🚀 ~ notView ~ val:', val);
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
      listData: {}, // 预算列表数据
      productRange: [], // 产品维度
      productCode: [], // 产品系列
      rowDataIndex: 0, // 行索引
    };
  },
  created() {},
  mounted() {
    // if (this.saveRef) {
    //   this.saveRef(this);
    // }
  },
  methods: {
    handleInput(e, index, type) {
      console.log('🚀 ~ handleInput ~ e, index, type:', e, index, type);
      if (type) {
        this.subjectList[index].rewardGranted = e.replace(/\D/g, '');
      } else {
        this.subjectList[index].scanRequired = e.replace(/\D/g, '');
      }
    },
    scoreInput(e, index) {
      const regex = /^\d{0,2}(\.\d{0,2})?$/;
      if (!regex.test(e)) {
        this.subjectList[index].increaseLimitRatio = '';
      }
    },
    // 删除行
    deletRow(row, rowIndex) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        console.log(this.subjectList, 'subjectList');

        this.$refs.fineTable.remove(row);
        this.subjectList.splice(rowIndex, 1);
        this.$emit('input', {
          subjectList: this.subjectList,
        });

        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // // 新增预算信息
    // addTable() {
    //   this.subjectList.push({
    //     productCode: '',
    //     productName: '',
    //   });
    //   this.$emit('input', {
    //     subjectList: this.subjectList,
    //   });
    // },
    // 打开点击弹窗产品名称
    productNameClick(index) {
      const params = {
        data: this.subjectList,
        selectionList: [],
        functionCode: 'productList',
        idKey: 'productCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      const data = this.getReset(val, [], 'id');
      this.subjectList = this.getReset(this.subjectList.concat(data), [], 'id');
      this.subjectList = this.subjectList.map((item) => ({
        productName: item.productName,
        productCode: item.productCode,
        productLevelName: item.productLevelName,
        productLevelCode: item.productLevelCode,
      }));
      this.$emit('input', {
        subjectList: this.subjectList,
      });
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 删除
    clearFn(e, code, index, aIndex) {
      e.stopPropagation();
    },
  },
};
</script>

<style lang="less" scoped>
.padding_50 {
  padding-left: 50px;
}
.form_title {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 0;
  margin-top: 14px;
  margin-bottom: 20px;
}
.edit-table {
  // padding-left: 50px;
  margin-left: 0 !important;
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
