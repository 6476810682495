var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "div",
        { staticClass: "padding_50" },
        [
          _c("vxe-toolbar", {
            scopedSlots: _vm._u([
              {
                key: "buttons",
                fn: function () {
                  return [
                    !_vm.disabled
                      ? _c(
                          "vxe-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.productNameClick()
                              },
                            },
                          },
                          [_vm._v("添加产品")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "vxe-table",
            {
              ref: "fineTable",
              attrs: {
                "show-overflow": "",
                data: _vm.subjectList,
                height: "300px",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { "min-width": "100", field: "rowIndex", title: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productLevelName",
                  title: "产品系列",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productCode",
                  title: "产品编码",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productName",
                  title: "产品名称",
                },
              }),
              _vm.scope === "1"
                ? _c("vxe-table-column", {
                    attrs: {
                      "min-width": "100",
                      field: "increaseLimitRatio",
                      title: "提额比例(%)",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  precision: 2,
                                  placeholder: "提额比例",
                                  disabled: _vm.disabled,
                                },
                                model: {
                                  value: row.increaseLimitRatio,
                                  callback: function ($$v) {
                                    _vm.$set(row, "increaseLimitRatio", $$v)
                                  },
                                  expression: "row.increaseLimitRatio",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      265986090
                    ),
                  })
                : _vm._e(),
              _vm.notView !== "view"
                ? _c("vxe-table-column", {
                    key: "操作",
                    attrs: { title: "操作", width: "100", "show-overflow": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deletRow(row, rowIndex)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1697997809
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }