import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import addForm from './components/addForm.vue';
import terminalConfigurationRuleList from './components/terminalConfigurationRuleList.vue';

formCreate.component('addForm', addForm);
formCreate.component('terminalConfigurationRuleList', terminalConfigurationRuleList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'increase_amount_activity_form',
    };
  },
  async created() {
    await this.getFormRule('increase_amount_activity_form');
  },
  methods: {
    // 设置规则
    setRule(item) {
      // console.log('item', item);
      if (item.field === 'addForm') {
        item.props = {
          ...item.props,
          disabled: this.formConfig.code === 'view',
        };
        console.log(item.props.disabled, 'disabled');
      } else if (item.field === 'scope') {
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const addFormRule = this.getRule('addForm');
            if (e === '0') {
              addFormRule.props = {
                ...addFormRule.props,
                scope: '0',
              };
              this.hiddenFields(false, ['increaseLimitRatio']);
            } else {
              addFormRule.props = {
                ...addFormRule.props,
                scope: '1',
              };
              this.hiddenFields(true, ['increaseLimitRatio']);
            }
          },
        };
      } else if (item.field === 'increaseLimitRatio') {
        item.props = {
          ...item.props,
          precision: 2,
          min: 0,
        };
      } else if (item.field === 'terminalConfigurationRuleName') {
        item.props = {
          ...item.props,
          notView: this.formConfig.code !== 'view',
        };
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const obj = e.tableData[0];
            delete obj.tableData;
            this.setValue({
              ...obj,
              terminalConfigurationRuleName: {
                ...obj,
              },
            });
          },
        };
      } else if (item.field === 'startDate') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'startDate',
              endField: 'endDate',
              gtNow: true,
            },
          },
        });
      } else if (item.field === 'endDate') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'startDate',
              endField: 'endDate',
              gtNow: true,
            },
            defaultTime: '23:59:59',
          },
        });
      }

      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      this.getRule('addForm').props.disabled = false;
      if (type === 'edit' || type === 'view') {
        request.get('/cps/v1/fxhIncreaseLimit/findById', {
          id,
        }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue({
              ...result,
              terminalConfigurationRuleName: {
                terminalConfigurationRuleName: result.terminalConfigurationRuleName,
                terminalConfigurationRuleCode: result.terminalConfigurationRuleCode,
              },
              addForm: {
                subjectList: result.productList,
              },
            });
            if (type === 'view') {
              this.getRule('addForm').props.disabled = true;
            } else {
              this.getRule('addForm').props.disabled = false;
            }
            const addFormRule = this.getRule('addForm');
            if (result.scope === '0') {
              addFormRule.props = {
                ...addFormRule.props,
                scope: '0',
                notView: type,
              };
              this.hiddenFields(false, ['increaseLimitRatio']);
            } else {
              addFormRule.props = {
                ...addFormRule.props,
                scope: '1',
                notView: type,
              };
              this.hiddenFields(true, ['increaseLimitRatio']);
            }
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      console.log('🚀 ~ submit ~ formData:', formData);
      if (formData) {
        const params = {
          scope: formData.scope,
          increaseLimitName: formData.increaseLimitName,
          increaseLimitRatio: formData.scope === '0' ? formData.increaseLimitRatio : 0,
          endDate: formData.endDate,
          startDate: formData.startDate,
          terminalConfigurationRuleCode: formData.terminalConfigurationRuleCode,
          terminalConfigurationRuleName: formData.terminalConfigurationRuleName.terminalConfigurationRuleName,
          productList: formData.addForm.subjectList,
        };
        // 默认新增
        let url = '/cps/v1/fxhIncreaseLimit/create';
        // 有id  就是编辑
        if (this.formConfig.id) {
          params.id = this.formConfig.id;
          url = '/cps/v1/fxhIncreaseLimit/updae';
        }
        console.log(params, 'params');
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
