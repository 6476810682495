<template>
  <div>
    <!-- 输入框 -->
    <el-input
      v-model="pageData.terminalConfigurationRuleName"
      placeholder="档级权益"
      @click.native="openSelectModal"
      :disabled="!notView"
      ref="inputRef"
      readonly
      clearable
    ></el-input>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    SelectConfig,
  },
  props: {
    title: String,
    value: {
      type: Object,
      default: () => ({}),
    },
    notView: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    functionCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      pageData: {},
    };
  },
  methods: {
    openSelectModal() {
      const params = {
        requestType: 'GET',
        functionCode: 'terminalConfigurationRuleList',
        idKey: 'terminalConfigurationRuleCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
          ...this.params,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(e) {
      this.tableData = e || [];
      this.pageData = {
        ...this.pageData,
        tableData: this.tableData,
        terminalConfigurationRuleCode:
          e && e.map((el) => el.terminalConfigurationRuleCode).join(','),
        terminalConfigurationRuleName:
          e && e.map((el) => el.terminalConfigurationRuleName).join(','),
      };
      this.toFather();
    },
    toFather() {
      this.pageData = {
        ...this.pageData,
        tableData: this.tableData,
      };
      this.$emit('input', this.pageData);
      this.$emit('change', this.pageData);
    },
    removeEvent(row) {
      this.tableData = this.tableData.filter(
        (item) => item.terminalConfigurationRuleCode
          !== row.terminalConfigurationRuleCode,
      );
      this.toFather();
    },
    changeEvent() {
      this.pageData = {
        ...this.pageData,
        tableData: this.tableData,
      };
      this.$emit('input', this.pageData);
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        console.log('🚀 ~ handler ~ val:', val);
        this.pageData.terminalConfigurationRuleName = val.terminalConfigurationRuleName;
      },
    },
    params: {
      handler(val) {},
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style></style>
