<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  name: 'increase_amount_activity_list',
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestType: 'GET',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('increase_amount_activity_list');
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      console.log({ val, row });
      console.log('this.formConfig', this.formConfig);

      if (['add', 'edit', 'view'].includes(val.code)) {
        this.formConfig = {};
        this.formName = 'Form';
        if (val.code === 'view') {
          this.modalConfig.title = '查看';
        } else if (val.code === 'edit') {
          this.modalConfig.title = '编辑';
        } else {
          this.modalConfig.title = '新增';
        }
        this.modalConfig.width = '70%';
        this.modalConfig.height = '600px';
        this.formConfig = {
          type: val.code,
          id: row.id || '',
          code: val.code || '',
        };
        this.openFull();
      }
    },
    // 删除
    deleteClick({ val }) {
      console.log(111);

      const params = this.selectRow.map((item) => item.id);
      if (val.code === 'delete') {
        if (this.selection.length > 0) {
          this.$confirm('是否确定要删除？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request
                .post('/cps/v1/fxhIncreaseLimit/delete', params)
                .then((res) => {
                  if (res.success) {
                    this.$message({
                      type: 'success',
                      message: '操作成功',
                    });
                    this.getList();
                  }
                });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
    },
  },
};
</script>
